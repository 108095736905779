<template>
  <div class="background-color">
    <div class="container">
      <div
        class="is-flex py-6 is-align-items-center is-justify-content-center"
        style="min-height: 100vh !important"
      >
        <div
          class="column is-10-widescreen is-10-desktop is-10-tablet is-12-mobile pr-5 pl-5"
        >
          <img
            src="https://ecommerce.skylubricantes.com/storage/icons-sky/icons/Carritosad.png"
            class="mb-3"
            alt=""
          />
          <h1 class="text-red-big mb-0">404</h1>
          <h6 class="text-color-gray mb-5">Error</h6>
          <p class="text-p">
            Nuestros mecánicos están reparando el motor de este segmento.
            <b class="text-red">Muy pronto estará de nuevo en movimiento.</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./not-found.scss" />
